import React, { useRef, useState, useEffect } from "react";
import "../assets/styles/MainSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from '@lottielab/lottie-player/react';
import {faLinkedin, faGithub, faInstagram,} from "@fortawesome/free-brands-svg-icons";
import Xarrow from "react-xarrows";
import { TypeAnimation } from "react-type-animation";
import GraphemeSplitter from "grapheme-splitter"; // npm i grapheme-splitter

const splitter = new GraphemeSplitter();

function MainSection() {
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const box1Ref = useRef(null);
  const box2Ref = useRef(null);

  const [arrowSize, setArrowSize] = useState({ strokeWidth: 2 });

  useEffect(() => {
    // Function to update arrow size based on screen width
    const updateArrowSize = () => {
      const width = window.innerWidth;
      const newStrokeWidth = width < 768 ? 1 : width < 1024 ? 2 : 3; // Adjust stroke width
      setArrowSize({ strokeWidth: newStrokeWidth });
    };

    updateArrowSize();
    window.addEventListener("resize", updateArrowSize);

    return () => window.removeEventListener("resize", updateArrowSize);
  }, []);

  return (
    <section className="main-section">
      <div style={{ height: "42px", margin: "10px 20px 50px 20px" }}>
        <TypeAnimation splitter={(str) => splitter.splitGraphemes(str)} cursor={false} className="type" sequence={[ "Hello 🇬🇧", 3500, "Ciao 🇮🇹", 3500, "你好 🇨🇳", 3500, "дравейте 🇧🇬", 3500, "Hola 🇪🇸", 3500, "Bonjour 🇫🇷", 3500, "नमस्ते 🇮🇳", 2000, "Olá 🇵🇹", 3500, "Hallo 🇩🇪", 3500, "こんにちは 🇯🇵", 3500, "안녕하세요 🇰🇷", 3500, ]} style={{ fontSize: "2em", fontWeight: "600", color: "white" }} repeat={Infinity} />
      </div>

      <div style={{ width: "100%", height: window.innerHeight < window.innerWidth ? "20vw" : "20vh", display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0 10px 0" }} >
        <img
          src={`${process.env.PUBLIC_URL}/images/leo.png`}
          alt="Leonardo Scappatura"
          className="profile-image"
        />
      </div>

      <div className="text">
        <TypeAnimation splitter={(str) => splitter.splitGraphemes(str)} sequence={[ "My name is Leonqrdp Sc", 1000, "My name is Leonardo scapp", 1000, "My name is Leonardo Scappatura 🔥", 1000, ]} cursor={false} className="type" speed={45} style={{ fontSize: "1.5em", fontWeight: "600", color: "white", }} />
      </div>

      <div style={{ width: "80vw", maxWidth: "700px", height: "auto", textAlign: "center", padding: "1em", }} >
        <p>
          I'm a master's student in High Performance Computing Engineering at
          Politecnico di Milano. <br /> <br />I graduated with honors in Computer
          Science & Artificial Intelligence at La Sapienza University of Rome. <br/> <br/> 
          My current interests are deep learning and computational neuroscience.
        </p>
      </div>

      <div className="social-links">
        <div id="elem1" style={{height: "auto", width:"24px", padding: "5px"}} onClick={() => openLink("https://www.linkedin.com/in/leonardosca")} >
          {/* <FontAwesomeIcon id="elem1" icon={faLinkedin} onClick={() => openLink("https://www.linkedin.com/in/leonardosca")} />  */}
          <Lottie  src="https://cdn.lottielab.com/l/BqmiRJjY1p3dGp.json" autoplay />
        </div>
        <div style={{height: "auto",width:"24px", padding: "5px"}} onClick={() => openLink("https://github.com/leonard013")} >
          {/* <FontAwesomeIcon icon={faGithub} onClick={() => openLink("https://github.com/leonard013")} />  */}
          <Lottie src="https://cdn.lottielab.com/l/o9KuDRaLMX8Fb0.json" autoplay />
        </div>
        <div id="elem2" style={{height: "auto",width:"24px", padding: "5px"}} onClick={() => openLink("https://www.instagram.com/leonardoscappatura/") } >
          {/* <FontAwesomeIcon id="elem2" icon={faInstagram} onClick={() => openLink("https://www.instagram.com/leonardoscappatura/") } />  */}
          <Lottie src="https://cdn.lottielab.com/l/5DgwFXaE9hYXiv.json" autoplay />

        </div>
      </div>

      <div style={{ width: "80vw", height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: "80px", }} >
        <div ref={box1Ref} className="text-by-hand">
          {" "} Only for <br /> serious stuff{" "}
        </div>
        <div ref={box2Ref} className="text-by-hand">
          {" "} Just for fun <br /> or to grab an ice cream{" "}
        </div>
      </div>

      <Xarrow path="smooth" curveness={true} color="white" start={box1Ref} end="elem1" startAnchor={{ position: "auto", offset: { x: 5 } }} endAnchor={{ position: "left", offset: { x: -10 } }} strokeWidth={arrowSize.strokeWidth} />
      <Xarrow color="white" curveness={true} start={box2Ref} end="elem2" startAnchor={{ position: window.innerWidth < 1235 ? "top" : "auto", offset: { x: -5 }, }} endAnchor={{ position: "right", offset: { x: 10 } }} strokeWidth={arrowSize.strokeWidth} />

    </section>
  );
}

export default MainSection;
